// Source: app/scripts/config.js
/**
 * INSPINIA - Responsive Admin Theme e
 *
 * Inspinia theme use AngularUI Router to manage routing and views
 * Each view are defined as state.
 * Initial there are written stat for all view in theme.
 *
 */
//function config($stateProvider, $urlRouterProvider,$translateProvider) {
//    // Initialize angular-translate
//    $translateProvider.useStaticFilesLoader({
//        prefix: 'i18n/',
//        suffix: '.json'
//    });
//
//    $translateProvider.preferredLanguage("mk");
//
//    //$translateProvider.useCookieStorage();
//    $urlRouterProvider.otherwise("/index/main");
//
//
//    $stateProvider
//
//        .state('index', {
//            abstract: true,
//            url: "/index",
//            templateUrl: "../app/views/common/content.html",
//        })
//        .state('index.main', {
//            url: "/main",
//            templateUrl: "../app/views/main.html",
//            data: { pageTitle: 'Example view' }
//        })
//        .state('index.minor', {
//            url: "/minor",
//            templateUrl: "../app/views/minor.html",
//            data: { pageTitle: 'Example view' }
//        })
//        .state('index.login', {
//            url: "/login",
//            templateUrl: "../app/views/login.html",
//            data: { pageTitle: 'Login' }
//        })
//        .state('index.newevent', {
//            url: "/newevent",
//            templateUrl: "../app/views/newEvent.html",
//            data: {pageTitle: 'New Event'}
//        })
//}
//inspinia
//    .config(config)
//    .run(function($rootScope, $state) {
//        $rootScope.$state = $state;
//    });

var globalConfig = {
    apiUrl: '',
    loginUrl: '/Account/Login'
}

var dateFormat = {
    mk: 'DD.MM.YYYY'
}

function getApiUrl(url) {
    return globalConfig.apiUrl + url;
};

function getGlobalUrl(url) {
    return getApiUrl(url);
}

function getLoginUrl() {
    return globalConfig.loginUrl;
};

function getDateFormat() {
    return dateFormat.mk;
}