// Source: app/scripts/controllers.js
/**
 * INSPINIA - Responsive Admin Theme
 *
 */

/**
 * MainCtrl - controller
 */
(function () {
    inspinia.controller("MyMainCtrl", ["projectDetails.Model", "$window","$location", function (projectDetails, $window, $location) {
        var self = this;
        self.filter = {page:1,count:21}
        self.items = [];
        self.init = function() {
            self.list();
        }
        self.goto = function(item) {
            $location.href = "#index/project/edit/"+item.id;
        }
        self.list = function () {
            projectDetails.pagedMy(self.filter.page, self.filter.count).then(function (res) {
                self.items = res.data.content;
                self.total = res.data.totalElements;
                self.filter.count = res.data.size;
            });
        }
        self.redirect = function (url) {
            $window.location.href = url;
        }
        self.init();
    }])
    inspinia.controller("MainCtrl", ["projectDetails.Model", "$window","$stateParams", function (projectDetails, $window,$stateParams) {
        var self = this;
        self.filter = {page:1,count:21}
        self.items = [];
        self.init = function() {
            self.list();
        }
        self.callId = $stateParams.id!==undefined?$stateParams.id:0;
        self.list = function () {
            projectDetails.paged(self.callId, self.filter.page, self.filter.count).then(function (res) {
                self.items = res.data.content;
                self.total = res.data.totalElements;
                self.filter.count = res.data.size;
            });
        }
        self.redirect = function (url) {
            $window.location.href = url;
        }
        self.init();
    }])

    inspinia.controller("InternationalProject.View", ["projectDetails.Model", "$window","$stateParams", function (projectDetails, $window,$stateParams) {
        var self = this;
        self.filter = {page:1,count:21}
        self.items = [];
        self.init = function() {
            self.list();
        }
        self.list = function () {
            projectDetails.pagedInternational( self.filter.page, self.filter.count).then(function (res) {
                self.items = res.data.content;
                self.total = res.data.totalElements;
                self.filter.count = res.data.size;
            });
        }
        self.redirect = function (url) {
            $window.location.href = url;
        }
        self.init();
    }])

    inspinia.controller("ProjectController.Approve", ["projectDetails.Model", "$window","$location","$uibModal","generic.Model","logger", function (projectDetails, $window, $location,$uibModal,generic,logger) {
        var self = this;
        self.page = {page:1,count:21}
        self.items = [];
        self.init = function() {
            self.list();
            generic.query("/call").then(function(data) {
                self.calls = data.data;
            });
            generic.query("/projectstatuses").then(function(data) {
                self.statuses = data.data._embedded.result;
            });
        }
        self.goto = function(item) {
            $location.href = "#index/project/edit/"+item.id;
        }
        self.list = function () {
            projectDetails.pagedMy( self.page.page,self.page.count).then(function (res) {
                self.items = res.data.content;
                self.total = res.data.totalElements;
                self.page.count = res.data.size;
            });

        }
        self.redirect = function (url) {
            $window.location.href = url;
        }
        self.acceptProject = function(item) {
            generic.query("/project/accept/"+item.id).then(function(data) {
                if (data.data.status) {
                    logger.logSuccess("Success");
                    self.filterFn();
                } else {
                    logger.logError("Failed");
                }
            })
        }


        self.deleteProject = function(item) {
            generic.query("/project/delete/"+item.id).then(function(data) {
                if (data.data.status) {
                    logger.logSuccess("Deleted");
                    self.filterFn();
                } else {
                    logger.logError("Failed");
                }
            })
        }

        self.showConfirmDelete = function (item) {
            self.item = item;
            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: 'confirmation.html',
                controller: 'Confirmation.Delete',
                controllerAs: 'main',
                size: 'md',
                resolve: {
                    textToDisplay:function() {
                        return "Потврда за бришење на запис?"
                    },
                    item: function() {
                        return item
                    },
                    onConfirm: function() {
                        return self.deleteProject;

                    },
                    onCancel:function() {
                        return ;
                    }
                }
            });
        }

        self.filterFn = function() {
            projectDetails.pagedFilter(self.filter||{},self.page.page,self.page.count).then(function (res) {
                self.items = res.data.content;
                self.total = res.data.totalElements;
                self.page.count = res.data.size;
            });

        }
        self.init();
    }]);
    inspinia.controller("Confirmation.Delete", ["$uibModal","$uibModalInstance","onConfirm", "onCancel","item","textToDisplay",function($uibModal,$uibModalInstance, onConfirm,onCancel,item,textToDisplay) {
        var self = this;
        self.item = item;
        self.textToDisplay = textToDisplay;
        self.confirm=  function() {
            onConfirm(item);
            $uibModalInstance.close();
        }
        self.cancel = function() {
            $uibModalInstance.close();
        }
    }]);
    inspinia.controller("Search.Project.View",["$location", function($location) {
        var self = this;
        self.search = function(searchText) {
            $location.path("/index/search/"+searchText);
        }
    }]);
    inspinia.controller("Search.Project.List",["projects.Search","$stateParams", function(searchService,$stateParams) {
        var self = this;
        searchService.search($stateParams.search).then(function(data) {
            var projects = [];
            _.each(data.data,function(item) {
                if (item.type==='national') {
                    item.url = '/#/index/project/'+item.id;
                } else {
                    item.url = '/#/index/main/international/'+item.id;
                }
                projects.push(item);
            });
            self.items = data.data;
        });
    }]);
}());
(function () {
    inspinia.controller("AdminMainCtrl", ["event.Model.Admin", "$window", '$uibModal', '$location', 'eventGroup.Model', function (eventModel, $window, $uibModal, $location, eventGroupModel) {
        var self = this;
        self.items = [];
        self.init = function () {
            self.activeEvent = new Object();
            eventModel.query().then(function (res) {
                self.items = res.data.model;
            });
            eventGroupModel.getActive().then(function (res) {
                self.activeEvent = res.data[0];
            });
            eventGroupModel.query().then(function (res) {
                self.eventGroups = res.data;
            });
        }
        self.queryEventsByEventSession = function (id) {
            eventModel.queryByEventSession(id).then(function (res) {
                self.items = res.data;
            });
        }
        self.redirect = function (url) {
            $window.location.href = url;
        }
        self.approve = function (id) {
            eventModel.eventAction(id, "approve").then(function (res) {
                if (res.data.status) {
                    alert("Success");
                } else {
                    alert("Error");
                }
                self.init();
            });


        }
        self.edit = function (id) {
            $location.path("/index/editevent/" + id);
        }
        self.reject = function (id) {
            eventModel.eventAction(id, "reject").then(function (res) {
                if (res.data.status) {
                    alert("Success");
                } else {
                    alert("Error");
                }
                self.init();
            });


        }
        self.saveWeight = function (item) {
            eventModel.eventWeight(item).then(function (res) {
                if (res.data.status) {
                    alert("Success");
                } else {
                    alert("Error");
                }
                self.init();
            });
        }
        self.saveWeights = function (items) {
            eventModel.eventWeights(items);
        }
        self.delete = function (id) {
            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: 'delete.html',
                controller: 'ConfirmationDeleteEvent',
                controllerAs: 'main',
                size: 'lg',
                resolve: {
                    item: function () {
                        return {"id": id};
                    },
                    init: function () {
                        return self.init;
                    }
                }
            });

        }
        self.sortableOptions = {
            stop: function (e, ui) {
                for (var index in self.items) {
                    self.items[index].weight = index;
                }
                self.saveWeights(self.items);
            }
        }
        self.init();
    }])
}());
(function () {
    inspinia.controller('ConfirmationDeleteEvent', [
        '$uibModalInstance', '$location', 'item', 'event.Model.Admin', 'init',
        function ($uibModalInstance, $location, item, eventModel, init) {
            var self = this;
            self.confirmDelete = function () {
                eventModel.eventAction(item.id, "delete").then(function (res) {
                    if (res.data.status) {
                        alert("Success");
                    } else {
                        alert("Error");
                    }
                    self.close();
                    init();
                });
            }
            self.close = function () {
                $uibModalInstance.close(false);
            }
        }]);
})();
(function () {
    inspinia.controller('ConfirmationNewEvent', [
        '$uibModalInstance', '$location', 'item','redirectUrl',
        function ($uibModalInstance, $location, item,redirectUrl) {
            var self = this;
            if (!redirectUrl) {
                redirectUrl="#/index/main";
            }
            self.close = function () {
                $uibModalInstance.close(false);
                window.location.href = redirectUrl;
            };
        }
    ]);
})();

(function () {
    inspinia.controller("ProjectController.New", ["$scope","$stateParams", "country.Model", 'generic.Model', 'project.Model', '$uibModal',  'eventGroup.Model', function (scope,$stateParams, data, generic, project, $uibModal,  eventGroupModel) {
        var self = this;
        self.init = function (){
            self.countries = new Array();
            self.project = new Object();
            self.project.members = [];
            self.isSaving = false;
            self.getCallById($stateParams.id);
        }

        self.getKeywords = function (filter) {
            return generic.getItemsFor("keywords", {"filter": filter}).then(function (data) {
                return data.data;
            });
        }
        self.getUsers = function (filter) {
            return generic.getItemsFor("users", {"filter": filter}).then(function (data) {
                return data.data;
            });
        }

        self.createProject = function (item) {
            self.isSaving = true;
            item.callId = self.call.id;
            project.save(item).then(function (res) {
                if (res.data.status) {
                    self.showConfirm(res.data.model);

                }
            }).finally(function() {
                self.isSaving = false;
            });
        }
        self.showConfirm = function (item) {
            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: 'confirmation.html',
                controller: 'ConfirmationNewEvent',
                controllerAs: 'main',
                size: 'md',
                resolve: {
                    item: function () {
                        return item;
                    },
                    redirectUrl: function() {
                        return "#/index/project/"+item.id;
                    }
                }
            });
        }
        self.getCallById = function(id) {
            generic.getItemsFor("/call/"+id).then(function(data) {
                self.call= data.data;
            });
        }
        self.showExternalModal = function(project) {
            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: 'add.external.user.html',
                controller: 'AddExternalUser',
                controllerAs: 'main',
                size: 'md',
                resolve: {
                    project:function() {
                        return project;
                    }
                }
            });
        }
        self.init();
    }]);
}());

(function () {
    inspinia.controller("ProjectController.Edit", ["$scope", "country.Model", 'generic.Model', 'project.Model', '$uibModal',  'eventGroup.Model','$stateParams', function (scope, data, generic, project, $uibModal, eventGroupModel,$stateParams) {
        var self = this;
        self.id = $stateParams["id"];
        self.init = function (){
            self.project = new Object();
            self.project.members = [];
            self.isSaving = false;
            self.getProjectById($stateParams["id"]);

        }
        self.getProjectById = function(id) {
            generic.getItemsFor("/project/edit/"+id).then(function(data) {
                self.project = data.data.model.project;
                self.project.projectId=self.id;
                self.project.name = data.data.model.projectDetails.name;
                self.project.nameEn = data.data.model.projectDetailsEn.name;

                self.project.members = data.data.model.members;
                self.project.owners = data.data.model.owners;
                self.project.projectDetailsId = data.data.model.projectDetails.id;
                self.project.projectDetailsEnId = data.data.model.projectDetailsEn.id;
                self.project.description = data.data.model.projectDetails.description;
                self.project.descriptionEn = data.data.model.projectDetailsEn.description;
                self.project.projectResults = data.data.model.projectDetails.projectResults;
                self.project.projectResultsEn = data.data.model.projectDetailsEn.projectResults;
                self.project.keywords = [];
                self.project.keywordsEn = [];
                _.each(data.data.model.keywords, function(item) {
                    self.project.keywords.push(item);
                });
                _.each(data.data.model.keywordsEn, function(item) {
                    self.project.keywordsEn.push(item);
                });
            })
        }
        self.modifyOwner = function(action,item) {
            generic.postData("/project/"+self.id+"/owner/"+action,item).then(function(data) {
                console.log(data.status);
            });
        }
        self.modifyMember = function(action,item) {
            generic.postData("/project/"+self.id+"/member/"+action,item).then(function(data) {
                console.log(data.status);
            });
        }
        self.modifyKeyword = function(action,lang,item) {
            generic.postData("/project/"+self.id+"/keyword/"+action+"/"+lang,item).then(function(data) {
                console.log(data.status);
            });
        }
        self.getKeywords = function (filter) {
            return generic.getItemsFor("keywords", {"filter": filter}).then(function (data) {
                return data.data;
            });
        }
        self.getUsers = function (filter) {
            return generic.getItemsFor("users", {"filter": filter}).then(function (data) {
                return data.data;
            });
        }

        self.createProject = function (item) {
            self.isSaving = true;
            project.save(item).then(function (res) {
                if (res.data.status) {
                    self.showConfirm(res.data.model);
                }
            }).finally(function(data) {
                self.isSaving = false;
            });
        }
        self.showConfirm = function (item) {
            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: 'confirmation.html',
                controller: 'ConfirmationNewEvent',
                controllerAs: 'main',
                size: 'md',
                resolve: {
                    item: function () {
                        return item;
                    },
                    redirectUrl:function() {
                        return "#/index/project/"+item.id;
                    }
                }
            });
        }
        self.showExternalModal = function(project) {
            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: 'add.external.user.html',
                controller: 'AddExternalUser',
                controllerAs: 'main',
                size: 'md',
                resolve: {
                    project:function() {
                        return project;
                    }
                }
            });
        }
        self.init();
    }]);
}());

(function() {
    inspinia.controller("AddExternalUser",["$uibModalInstance","generic.Model","project", function($uibModalInstance, model,project) {
        var self = this;
        self.init = function() {
            model.getItemsFor("degrees").then(function(data) {
                self.degrees = data.data;
            });
        }
        self.close = function() {
            $uibModalInstance.close();
        }
        self.save = function(item) {
            model.postData("user",item).then(function(data) {
                project.members.push(data.data.model);
                self.close();
            });
        }
        self.init();
    }]);
})

(function () {
    inspinia.controller("ProjectController.View", ["$stateParams", "projectDetails.Model", 'generic.Model', function ($stateParams, data, generic) {
        var self = this;
        self.countries = new Array();
        self.init = function () {
            data.getById($stateParams.id).then(function (data) {
                self.project = data.data;
            });
            self.hostname = getGlobalUrl("#index/project/" + $stateParams.id);
        }


        self.init();
    }]);
    inspinia.controller("ProjectInternationalController.View", ["$stateParams", "projectDetails.Model", 'generic.Model','$rootScope', function ($stateParams, data, generic,$rootScope) {
        var self = this;
        self.role = $rootScope.role;
        self.countries = new Array();
        self.init = function () {
            data.getInternationalById($stateParams.id).then(function (data) {
                self.project = data.data;
            });

            self.hostname = getGlobalUrl("#index/project/" + $stateParams.id);
        }


        self.init();
    }]);
    inspinia.controller("ProjectInternationalController.Edit", ["$stateParams", "projectDetails.Model", 'generic.Model','$uibModal','$rootScope', function ($stateParams, data, generic,$uibModal,$rootScope) {
        var self = this;
        self.role = $rootScope.role;
        self.countries = new Array();
        self.init = function () {
            generic.getItemsFor("/internationalproject/edit/"+$stateParams.id).then(function (data) {
                self.item = data.data;
            });
            generic.query("financers").then(function(data) {
                self.financers = data.data;
            });
            generic.query("projectInternationalTypes").then(function(data) {
                self.projectInternationalTypes = data.data;
            })
            self.hostname = getGlobalUrl("#index/project/" + $stateParams.id);
        }
        self.isSaving = false;
        self.save = function(item) {
            self.isSaving = true;
            generic.postData("/internationalproject",item).then(function(data) {
                if (data.data.status) {
                    self.showConfirm(item);
                }


            }).finally(function(data){
                self.isSaving = false;
            });
        }
        self.showConfirm = function (item) {
            var url = "#/index/main/international/"+item.project.id;
            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: 'confirmation.html',
                controller: 'ConfirmationNewEvent',
                controllerAs: 'main',
                size: 'md',
                resolve: {
                    item: function () {
                        return item;
                    },
                    redirectUrl:function() {
                        return url;
                    }
                }
            });
        }
        self.init();
    }])
}());
(function () {
    inspinia.controller("ProjectReportController.Edit", ["$stateParams", "projectDetails.Model", 'generic.Model','$uibModal', function ($stateParams, data, generic,$uibModal) {
        var self = this;

        self.init = function () {
            self.isSaving = false;
            data.getById($stateParams.id).then(function (data) {
                self.project = data.data;
                self.project.projectId=data.data.id;
            });
            self.hostname = getGlobalUrl("#index/project/" + $stateParams.id);
        }
        self.init();
        self.createReport = function(project) {
            self.isSaving = true;
            generic.postData("/project/report",self.project).then(function(data) {
                if (data.data.status) {
                    self.showConfirm(data.data);
                } else {
                    alert("Неуспешно ажурирање.");
                }

            }).finally(function() {
                self.isSaving = false;
            });
        }

        self.showConfirm = function (item) {
            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: 'confirmation.html',
                controller: 'ConfirmationNewEvent',
                controllerAs: 'main',
                size: 'md',
                resolve: {
                    item: function () {
                        return item;
                    },
                    redirectUrl: function(){
                        return "/#/index/project/"+item.id;
                    }
                }
            });
        }

    }]);
}());
(function () {
    inspinia.controller("NewEventController.Edit", ["$scope", "$stateParams", "$uibModal", "country.Model", 'generic.Model', 'event.Model', 'eventGroup.Model', function (scope, $stateParams, $uibModal, data, generic, event, eventGroupModel) {
        var self = this;
        self.countries = new Array();
        self.events = {
            click: function (mapModel, eventName, originalEventArgs) {
                //console.log("user defined event: " + eventName, mapModel, originalEventArgs);
                self.map.markers = [];
                var e = originalEventArgs[0];
                var lat = e.latLng.lat(), lon = e.latLng.lng();
                self.item.locationLat = lat;
                self.item.locationLng = lon;
                var clickedMarker = {
                    idKey: 1,
                    coords: {
                        latitude: lat,
                        longitude: lon
                    }

                };
                self.map.markers.push(clickedMarker);
                scope.$evalAsync();
            }
        }
        self.map = {center: {latitude: 41.9973, longitude: 21.4280}, zoom: 9, markers: []};
        self.init = function () {
            event.getById($stateParams.id).then(function (res) {
                self.item = res.data.model;
                self.item.coords = {
                    latitude: self.item.locationLat,
                    longitude: self.item.locationLng
                }
                var clickedMarker = [{
                    idKey: 1,
                    coords: {
                        latitude: self.item.locationLat,
                        longitude: self.item.locationLng
                    }

                }];
                self.map.markers = clickedMarker;

            });
            eventGroupModel.query().then(function (res) {
                self.eventGroups = res.data;
            });
            data.getItems().then(function (res) {
                self.countries = res.data;
            });
            generic.getItemsFor("audienceTypes").then(function (res) {
                self.audienceTypes = res.data;
            });
            generic.getItemsFor("pressAllowed").then(function (res) {
                self.pressAlloweds = res.data;
            });
            self.createEvent = function (item) {
                event.save(item).then(function (res) {
                    if (res.data.status) {
                        self.showConfirm(res.data.model);
                    }
                })
            }
            self.showConfirm = function (item) {
                var modalInstance = $uibModal.open({
                    animation: true,
                    templateUrl: 'confirmation.html',
                    controller: 'ConfirmationNewEvent',
                    controllerAs: 'main',
                    size: 'lg',
                    resolve: {
                        item: function () {
                            return item;
                        },
                        redirectUrl:function() {
                            return "#/index/project/"+item.id;
                        }


                    }
                });
            }

            self.hostname = getGlobalUrl("#index/event/" + $stateParams.id);
        }


        self.init();
    }]);
}());
(function () {
    inspinia.controller("TranslationController", ["$translate","$location","$rootScope", function ($translate, $location, $rootScope) {
        var self = this;
        self.changeLanguage = function (lang) {
            $translate.use(lang);
            $rootScope.languageKey = lang;
            location.reload(true);
        }
    }]);
}());

(function () {
    inspinia.controller("EventSessionsController.View", ["$translate", '$rootScope', '$uibModal', 'generic.Model', 'generic.Admin.Model', function ($translate, $rootScope, $uibModal, model, adminModel) {
        var self = this;

        self.init = function () {
            self.list();
        }

        self.list = function () {
            model.getItemsFor("eventgroup").then(function (data) {
                self.items = data.data;
            })
        }
        self.activate = function (item) {
            adminModel.postData("eventgroup/activate", item).then(function (data) {
                if (data.data.status) {
                    $rootScope.init();
                    self.init();
                    alert("Success");

                }
            });
        }
        self.delete = function (item) {
            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: 'delete.html',
                controller: 'ConfirmationDeleteEventGroup',
                controllerAs: 'main',
                size: 'lg',
                resolve: {
                    item: function () {
                        return item
                    },
                    init: function () {
                        return self.init;
                    }
                }
            });
        }

        self.init();
    }]);

})();


(function () {
    inspinia.controller('ConfirmationDeleteEventGroup', [
        '$uibModalInstance', '$location', 'item', 'generic.Admin.Model', 'init',
        function ($uibModalInstance, $location, item, eventGroupModel, init) {
            var self = this;
            self.confirmDelete = function () {
                eventGroupModel.postData("/eventgroup/delete", {'id': item.id}).then(function (res) {
                    if (res.data.status) {
                        alert("Success");
                    } else {
                        alert("Error");
                    }
                    self.close();
                    init();
                });
            }
            self.close = function () {
                $uibModalInstance.close(false);
            }
        }]);
})();

(function () {
    inspinia.controller("EventSessionsController.New", ["$translate", 'generic.Admin.Model', 'Upload', function ($translate, model, Upload) {
        var self = this;
        self.eventsession = new Object();
        self.createEventSession = function (eventsession) {
            model.postData("eventgroup", eventsession).then(function (data) {
                if (data.data.status === true) {
                    alert("Success");
                }
            })
        }
        self.uploadLogo = function (file) {
            Upload.upload({
                url: '/rest/utilities/upload',
                data: {file: file}
            }).then(function (resp) {
                if (resp.data.status) {
                    self.eventsession.eventLogo = resp.data.model;
                }
                console.log(resp.data.model);
                console.log('Success ' + resp.config.data.file.name + 'uploaded. Response: ' + resp.data);
            }, function (resp) {
                console.log('Error status: ' + resp.status);
            }, function (evt) {
                var progressPercentage = parseInt(100.0 * evt.loaded / evt.total);
                console.log('progress: ' + progressPercentage + '% ' + evt.config.data.file.name);
            });
        };

        self.uploadCover = function (file) {
            Upload.upload({
                url: '/rest/utilities/upload',
                data: {file: file}
            }).then(function (resp) {
                if (resp.data.status) {
                    self.eventsession.eventCover = resp.data.model;
                }
                console.log(resp.data.model);
                console.log('Success ' + resp.config.data.file.name + 'uploaded. Response: ' + resp.data);
            }, function (resp) {
                console.log('Error status: ' + resp.status);
            }, function (evt) {
                var progressPercentage = parseInt(100.0 * evt.loaded / evt.total);
                console.log('progress: ' + progressPercentage + '% ' + evt.config.data.file.name);
            });
        };

    }]);

})();

/**
 * Created by KM on 19-Sep-16.
 */

(function () {
    inspinia.controller('EventGroupController.Edit', [
        '$location', '$stateParams', 'generic.Admin.Model', 'Upload',
        function ($location, $stateParams, model, Upload) {
            var self = this;
            self.init = function () {
                self.load();
            }

            self.load = function () {
                model.getById("eventgroup", $stateParams.id).then(function (res) {
                    self.eventsession = res.data;
                });
            }

            self.init();

            self.uploadLogo = function (file) {
                Upload.upload({
                    url: '/rest/utilities/upload',
                    data: {file: file}
                }).then(function (resp) {
                    if (resp.data.status) {
                        self.eventsession.eventLogo = resp.data.model;
                    }
                    console.log(resp.data.model);
                    console.log('Success ' + resp.config.data.file.name + 'uploaded. Response: ' + resp.data);
                }, function (resp) {
                    console.log('Error status: ' + resp.status);
                }, function (evt) {
                    var progressPercentage = parseInt(100.0 * evt.loaded / evt.total);
                    console.log('progress: ' + progressPercentage + '% ' + evt.config.data.file.name);
                });
            };

            self.uploadCover = function (file) {
                Upload.upload({
                    url: '/rest/utilities/upload',
                    data: {file: file}
                }).then(function (resp) {
                    if (resp.data.status) {
                        self.eventsession.eventCover = resp.data.model;
                    }
                    console.log(resp.data.model);
                    console.log('Success ' + resp.config.data.file.name + 'uploaded. Response: ' + resp.data);
                }, function (resp) {
                    console.log('Error status: ' + resp.status);
                }, function (evt) {
                    var progressPercentage = parseInt(100.0 * evt.loaded / evt.total);
                    console.log('progress: ' + progressPercentage + '% ' + evt.config.data.file.name);
                });
            };

            self.createEventSession = function (eventsession) {
                model.postData("eventgroup", eventsession).then(function (data) {
                    if (data.data.status === true) {
                        alert("Success");
                    }
                })
            }
        }
    ]);
})();
