// Source: app/scripts/models.js
/**
 * Created by KM on 21-Aug-16.
 */
(function () {
    inspinia.service('country.Model', [
        'mainDataService', function (main) {
            var serviceName = '/rest/';

            this.getItems = function (q) {
                return main.getData(serviceName + 'countries', q);
            };

        }
    ]);
    inspinia.service('generic.Model', [
        'mainDataService', function(main) {
            var serviceName = '/rest/';
            this.query = function(f) {
                return main.getData(serviceName+f);
            }
            this.getItemsFor = function(f, g) {
                return main.getData(serviceName + f, g);
            };
            this.postData = function(f,g ) {
                return main.postData(serviceName+f,g);
            }

        }
    ]);
    inspinia.service('generic.Admin.Model', [
        'mainDataService', function(main) {
            var serviceName = '/admin/rest/';
            this.getItemsFor = function(f, g) {
                return main.getData(serviceName + f, g);
            };
            this.postData = function(f,g ) {
                return main.postData(serviceName+f,g);
            }
            this.getById = function(f,id) {
                return main.getData(serviceName+f+"/"+id);
            }
        }
    ]);
    inspinia.service('call.Model', [
        'mainDataService', function(main) {
            var serviceName = '/rest/';
            this.save = function(item) {
                return main.postData(serviceName + "call", item);
            };
            this.query = function() {
                return main.getData(serviceName+"call");
            }
            this.getById = function(id) {
                return main.getData(serviceName+"call/"+id);
            }
        }
    ]);
    inspinia.service('project.Model', [
        'mainDataService', function(main) {
            var serviceName = '/rest/';
            this.save = function(item) {
                return main.postData(serviceName + "project", item);
            };
            this.query = function() {
                return main.getData(serviceName+"project");
            }
            this.getById = function(id) {
                return main.getData(serviceName+"project/"+id);
            }
        }
    ]);
    inspinia.service('projectDetails.Model', [
        'mainDataService', function(main) {
            var serviceName = '/rest/';
            this.save = function(item) {
                return main.postData(serviceName + "projectdetails", item);
            };
            this.query = function() {
                return main.getData(serviceName+"projectdetails");
            }
            this.paged = function(callId,page,count) {
                return main.getData(serviceName+"projectdetails/paged",{"callId":callId,"count":count, "page":page});
            }
            this.pagedMy = function(page,count) {
                return main.getData(serviceName+"projectdetails/paged",{"count":count, "page":page,"personalized":true});
            }
            this.pagedInternational = function(page,count) {
                return main.getData(serviceName+"internationalproject/paged",{"count":count, "page":page,"personalized":true});
            }
            this.getById = function(id) {
                return main.getData(serviceName+"projectdetails/"+id);
            }
            this.getInternationalById = function(id) {
                return main.getData(serviceName+"internationalproject/"+id);
            }
            this.pagedFilter = function(filter,page,count) {
                return main.getData(serviceName+"projectdetails/paged/filter",{'count':count,'page':page,'filter':filter});
            }
        }
    ]);
    inspinia.service('event.Model.Admin', [
        'mainDataService', function(main) {
            var serviceName = '/admin/rest/';
            this.save = function(item) {
                return main.postData(serviceName + "event", item);
            };
            this.query = function() {
                return main.getData(serviceName+"event/list");
            }
            this.getById = function(id) {
                return main.getData(serviceName+"event/"+id);
            }
            this.eventAction = function(id,action) {
                return main.getData(serviceName+"event/"+id+"/"+action);
            }
            this.eventWeight = function(item) {
                return main.postData(serviceName+"event/weight", item);
            }
            this.queryByEventSession = function(id) {
                return main.getData(serviceName+"event/list/eventgroup/"+id);
            }
            this.eventWeights = function(items) {
                return main.postData(serviceName+"event/weights", items);
            }
        }
    ]);
    inspinia.service('eventGroup.Model',[
        'mainDataService', function(main) {
            var serviceName = '/rest/';
            this.getActive = function() {
                return main.getData(serviceName+"eventgroup/active");
            }
            this.query = function() {
                return main.getData(serviceName+"eventgroup");
            }
        }
    ]);
    inspinia.service("projects.Search", [
        'mainDataService', function(main) {
            var serviceName = '/rest/search/projects';
            this.search = function(searchText) {
                return main.getData(serviceName+"?q="+searchText);
            }
        }
    ])
})();
