// Source: app/scripts/interceptor.js
(function() {
    inspinia.factory('LanguageHttpInterceptors', function ($q, $location, $rootScope, $translate) {

        return {

            'request': function (config) {


                // Add token in each request header
                var lang =  $rootScope.languageKey || $translate.use();
                if (lang===undefined) {
                    lang = "mk";
                }
                config.headers['Locale'] = lang;

                return config || $q.when(config);
            }
        };
    });
})();
