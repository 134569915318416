// Source: app/scripts/app.js
(function () {
    window.inspinia = angular.module('inspinia', [
        'ui.router',
        'ui.bootstrap' ,
        'ngCookies',
        'pascalprecht.translate',
        'toastr',
        'ui.bootstrap',
        'ngSanitize',
        'ngFileUpload',
        'angular.filter',
        'ui.sortable',
        'ngTagsInput',
        'wu.masonry'
    ]);

    inspinia.value('backendServerUrl', window.location.hostname);

    inspinia.config([
        '$stateProvider','$urlRouterProvider','$httpProvider','$translateProvider',
        function ( $stateProvider, $urlRouterProvider,$httpProvider,$translateProvider) {
            //$locationProvider.html5Mode(true);
            //$httpProvider.defaults.headers.common["X-Requested-With"] = 'XMLHttpRequest';


            $translateProvider.useCookieStorage();
            $translateProvider.useUrlLoader("i18n/translations");
            /*$translateProvider.useStaticFilesLoader({
                prefix: 'i18n/',
                suffix: '.json'
            });*/

            $translateProvider.preferredLanguage("mk");


            $urlRouterProvider.otherwise("/index/call/national");

            $httpProvider.interceptors.push('LanguageHttpInterceptors');

            $stateProvider

                .state('index', {
                    abstract: true,
                    url: "/index",
                    templateUrl: "app/views/common/content.html",
                })
                .state('index.main', {
                    url: "/main",
                    templateUrl: "app/views/main.html",
                    controller:"MainCtrl",
                    controllerAs:"main",
                    data: {  pageTitle: 'Преглед на повици' }
                })
                .state('index.mainId', {
                    url: "/main/:id",
                    templateUrl: "app/views/main.html",
                    controller:"MainCtrl",
                    controllerAs:"main",
                    data: {  pageTitle: 'Преглед на повици' }
                })
                .state('index.minor', {
                    url: "/minor",
                    templateUrl: "app/views/minor.html",
                    data: { pageTitle: 'Преглед на настани' }
                })
                .state('index.login', {
                    url: "/login",
                    templateUrl: "app/views/login.html",
                    data: { pageTitle: 'Login' }
                })
                .state('index.newproject', {
                    url: "/newproject",
                    templateUrl: "app/views/project.New.html",
                    controller:"ProjectController.New",
                    controllerAs: "main",
                    data: {pageTitle: 'New Event'}
                })
                .state('index.myprojects', {
                    url: "/myprojects",
                    templateUrl: "app/views/main-edit.html",
                    controller:"MyMainCtrl",
                    controllerAs:"main",
                })
                .state('index.viewproject', {
                    url: "/project/:id",
                    templateUrl: "app/views/project.View.html",
                    controller:"ProjectController.View",
                    controllerAs:"main",
                    data: {pageTitle: "View Project"}
                })

                .state('index.viewinternationalproject', {
                    url: "/main/international/:id",
                    templateUrl: "app/views/projectInternational.View.html",
                    controller:"ProjectInternationalController.View",
                    controllerAs:"main",
                    data: {pageTitle: "View Project"}
                })
                .state('index.editinternationalproject', {
                    url: "/main/international/edit/:id",
                    templateUrl: "app/views/projectInternational.Edit.html",
                    controller:"ProjectInternationalController.Edit",
                    controllerAs:"main",
                    data: {pageTitle: "Edit Project"}
                })
                .state('index.projectEdit', {
                    url: "/project/edit/:id",
                    templateUrl: "app/views/project.Edit.html",
                    controller:"ProjectController.Edit",
                    controllerAs:"main",
                    data: {pageTitle: "Edit Project"}
                })
                .state('index.projectReport', {
                    url: "/project/report/:id",
                    templateUrl: "app/views/project.Report.html",
                    controller:"ProjectReportController.Edit",
                    controllerAs:"main",
                    data: {pageTitle: "Edit Project Report"}
                })

                .state('index.callNew', {
                    url: "/call/new",
                    templateUrl: "app/views/call.New.html",
                    controller:"CallController.New",
                    controllerAs:"main",
                    data: {pageTitle: "New Call"}
                })
                .state('index.callNational', {
                    url: "/call/national",
                    templateUrl: "app/views/call.View.html",
                    controller:"CallController.View",
                    controllerAs:"main",
                    data: {pageTitle: "View Calls",callType:"national"}

                })
                .state('index.callInternational', {
                    url: "/call/international",
                    templateUrl: "app/views/projects.International.List.html",
                    controller:"InternationalProject.View",
                    controllerAs:"main",
                    data: {pageTitle: "View Calls",callType:"international"}
                })
                .state('index.callApply', {
                    url: "/call/apply/:id",
                    templateUrl: "app/views/project.New.html",
                    controller:"ProjectController.New",
                    controllerAs:"main",
                    data: {pageTitle: "Apply Project"}
                })
                .state('index.projectApprove', {
                    url: "/project/approve/:id",
                    templateUrl: "app/views/project.Approve.html",
                    controller:"ProjectController.Approve",
                    controllerAs:"main",
                    data: {pageTitle: "Approve Project"}
                })
                .state('index.search', {
                    url: "/search/:search",
                    templateUrl: "app/views/search.html",
                    controller:'Search.Project.List',
                    controllerAs:"main",
                    data: {  pageTitle: 'Пребарување' }
                })


        }
    ]);


    inspinia.run([
        '$rootScope','$state','eventGroup.Model','$translate','generic.Model', function ($rootScope, $state, eventSession,$translate,generic) {
            $rootScope.$state = $state;
            $rootScope.authenticated = false;
            generic.getItemsFor("/user").then(function(data) {
                if (data.data.status) {
                    $rootScope.username = data.data.model.username;
                    $rootScope.role = data.data.model.role;
                    $rootScope.authenticated = true;
                } else {
                    $rootScope.authenticated = false;
                }

            }).catch(function(data) {

            })

            $rootScope.init = function() {
                eventSession.getActive().then(function(data) {
                    if (data.data.length>0) {
                        $rootScope.eventgroup = data.data[0];
                    }

                });
            }

            $rootScope.changeLanguage = function(lang) {
                $translate.use(key);
            }
            $rootScope.init();
            //$rootScope.$on('$locationChangeStart', function (event, newUrl, oldUrl) {
            //    if (oldUrl !== newUrl) {
            //        $window.location.href = newUrl;
            //    }
            //});
        }
    ]);
})();