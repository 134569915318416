// Source: app/scripts/dateFilters.js
(function () {
    inspinia.filter('dateTimeFilter', function() {
        return function (input) {
            var dt = input != null ? new Date(parseInt(input.substr(6))) : new Date();
            return dt.getFullYear().toString() + '-' + (dt.getMonth() + 1).toString() + '-' + dt.getDate().toString()
                + ' ' + (dt.getHours() < 10 ? '0' + dt.getHours() : dt.getHours()) + ':'
                + (dt.getMinutes() < 10 ? '0' + dt.getMinutes() : dt.getMinutes());
        };
    });

    inspinia.filter('moment', function () {
        return function (dateString, format) {
            return moment(dateString).format(format !== undefined ? format : getDateFormat());
        };
    });
}());