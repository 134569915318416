// Source: app/scripts/controllers-call.js
/**
 * Created by KM on 21-Oct-16.
 */



(function () {
    inspinia.controller("CallController.New", ["$scope","$rootScope",  'generic.Model', '$uibModal',"$state",  function (scope, $rootScope, generic,  $uibModal, $state) {
        var self = this;
        self.autheticated = $rootScope.authenticated;

        self.getFinancers = function() {
            generic.query("financers").then(function(data) {
                self.financers = data.data;
            })
        }
        self.getCallStatuses = function() {
            generic.query("callstatuses").then(function(data) {
                self.statuses = data.data._embedded.result;
            })
        }
        self.getCallTypes = function() {
            generic.query("calltype").then(function(data) {
                self.callTypes = data.data._embedded.callTypes;
            })
        }

        self.init = function (){
            self.financers = new Array();
            self.isSaving = false;
            self.getFinancers();
            self.getCallStatuses();
            self.getCallTypes();
        }

        self.init();



        self.createCall = function (item) {
            self.isSaving = true;
            generic.postData("call",item).then(function (res) {
                if (res.data.status) {
                    self.showConfirm(res.data.model);
                }
            }).finally(function(data){
                self.isSaving = false;
            });
        }
        self.showConfirm = function (item) {
            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: 'confirmation.html',
                controller: 'ConfirmationNewEvent',
                controllerAs: 'main',
                size: 'md',
                resolve: {
                    item: function () {
                        return item;
                    },
                    redirectUrl: function(){
                        return "/#/index/call/national";
                    }
                }
            });
        }
        self.showExternalModal = function(project) {
            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: 'add.external.user.html',
                controller: 'AddExternalUser',
                controllerAs: 'main',
                size: 'md',
                resolve: {
                    project:function() {
                        return project;
                    }
                }
            });
        }
    }]);
}());
(function () {
    inspinia.controller("CallController.View", ["$scope","$rootScope","$location", 'generic.Model', '$uibModal',"$state", function (scope, $rootScope, $location, generic, $uibModal, $state) {
        var self = this;
        self.autheticated = $rootScope.authenticated;
        var initCalls = function() {
            var callType = $state.current.data.callType;
            generic.query("call/"+callType+"/active").then(function(data) {
                self.items = data.data;
            });
        }
        self.init = function() {
            initCalls();
        }
        self.apply = function(item) {
            $location.path("/index/call/apply/"+item.id);
        }
        self.init();
    }])
}());